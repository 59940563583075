import { useEffect, useState } from "react";

const useIP = () => {
  const [ipData, setIpData] = useState({});
  const [ipAddress, setIpAddress] = useState("");
  const [countryCode, setCountryCode] = useState("ae");
  const [city, setCity] = useState("")
  const [region, setRegion] = useState("")
  const [countryLabel, setCountryFullName] = useState("");

  useEffect(() => {
    // Define the callback function
    window.getgeoip = (data) => {
      setIpData(data);
      setCountryCode(data?.country_code?.toLowerCase());
      setRegion(data?.region)
      setCity(data?.city)
      setCountryFullName(data?.country)
      if (data?.ip && data.ip !== ipAddress) {
        setIpAddress(data.ip);
      }
    };

    // Create a script element to fetch JSONP data
    const script = document.createElement("script");
    script.src = "https://api.seeip.org/geoip?callback=getgeoip";
    document.body.appendChild(script);

    // Clean up the script tag after execution
    return () => {
      document.body.removeChild(script);
      delete window.getgeoip;
    };
  }, []); // Run only once on mount

  return {
    ...ipData,
    ipAddress,
    countryCode,
    city,
    region,
    countryLabel,
  };
};

export default useIP;